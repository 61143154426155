<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xxl-12">
          <div class="gutter-b">
            <card title="Kurum Listesi">
              <v-menu slot="toolbar" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" outlined dark v-on="on">
                    İşlemler
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="schoolAddModal = true">
                    Kurum Ekle
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="row">
                <div class="col-md-6">
                  <v-menu
                    max-height="300px"
                    allow-overflow
                    :close-on-content-click="false"
                    offset-y
                    @click.stop.prevent
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="normal" block outlined v-on="on">
                        Filtrele <v-icon small>filter_list</v-icon>
                      </v-btn>
                    </template>
                    <v-text-field
                      v-model="treeSearch"
                      append-icon="mdi-magnify"
                      label="Şehir ara"
                      single-line
                      hide-details
                      style="padding: 10px 10px 0; background-color: white"
                    ></v-text-field>
                    <v-treeview
                      v-model="filter"
                      style="background-color: white;"
                      selectable
                      :search="treeSearch"
                      selection-type="independent"
                      :items="iller"
                      :load-children="getChildren"
                    ></v-treeview>
                  </v-menu>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    :value="search"
                    @change="v => (search = v)"
                    append-icon="mdi-magnify"
                    label="Kurum ara"
                    single-line
                    style="padding: 0; margin: 0"
                  ></v-text-field>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="schools"
                :loading="loading"
                :server-items-length="total"
                :options.sync="options"
                :search="search"
                multi-sort
              >
                <template v-slot:item.name="{ item }">
                  <router-link
                    :to="{ name: 'school', params: { schoolId: item.id } }"
                  >
                    {{ item.name }}
                  </router-link>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="$refs.schoolEditModal.open(item)" class="mr-2">
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    small
                    @click="
                      deletePromt = true;
                      deleteId = item.id;
                    "
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </card>
            <DeletePromt
              :visible="deletePromt"
              @close="deletePromt = false"
              @done="deleteSchool()"
            >
            </DeletePromt>
            <SchoolAdd
              :visible="schoolAddModal"
              @close="schoolAddModal = false"
              @done="getSchoolList()"
            ></SchoolAdd>

            <SchoolEdit ref="schoolEditModal" @refreshList="getSchoolList" />
          </div>
        </div>
      </div>
      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import SchoolAdd from './SchoolAdd';
import SchoolEdit from './SchoolEdit';
import DeletePromt from '@/view/content/components/DeletePromt';

export default {
  data() {
    return {
      title: 'Kurum Listesi',
      headers: [
        { text: 'Kurum Adı', value: 'name' },
        { text: 'İl', value: 'iller.il_adi' },
        { text: 'İlce', value: 'ilceler.ilce_adi' },
        { text: 'Semt', value: 'semtler.semt_adi' },
        { text: 'Mahalle', value: 'mahalleler.mahalle_adi' },
        { text: 'İşlemler', value: 'actions' }
      ],
      schools: [],
      options: {
        itemsPerPage: 10
      },
      schoolAddModal: false,
      deletePromt: false,
      deleteId: null,
      treeSearch: '',
      iller: [],
      filter: [],
      search: '',
      loading: true,
      total: 0
    };
  },
  components: { SchoolAdd, SchoolEdit, DeletePromt },
  mounted() {
    this.getSchoolList();
    this.getIlList();
  },
  methods: {
    get(a) {
      console.log(a);
    },
    async getSchoolList() {
      try {
        const {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          search,
          filter
        } = this.requestQuery;

        const sort = {};
        sortBy.map((item, index) => {
          const array = item.split('.');
          if (array.length === 1) {
            sort[item] = sortDesc[index] ? 'desc' : 'asc';
          } else {
            sort[array[0]] = {};
            sort[array[0]][array[1]] = sortDesc[index] ? 'desc' : 'asc';
          }
        });

        const { data } = await this.axios.get('admin/schools', {
          params: {
            page: {
              size: itemsPerPage,
              number: page
            },
            sort,
            search: {
              name: search
            },
            filter
          }
        });
        this.loading = false;
        this.schools = data.items;
        this.total = data.totalCount;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteSchool() {
      try {
        await this.axios.delete('admin/schools/' + this.deleteId);

        this.getSchoolList();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async getIlList() {
      try {
        const { data } = await this.axios.get('admin/address/iller');

        this.iller = data.map(il => {
          return {
            id: 'ilId-' + il.id,
            name: il.il_adi,
            children: []
          };
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getChildren(parent) {
      try {
        const parentType = parent.id.split('-')[0];
        const parentId = parent.id.split('-')[1];

        let response;

        switch (parentType) {
          case 'ilId':
            response = await this.axios.get(
              `admin/address/iller/${parentId}/ilceler`
            );
            parent.children = response.data.map(ilce => {
              return {
                id: 'ilceId-' + ilce.id,
                name: ilce.ilce_adi,
                children: []
              };
            });
            break;
          case 'ilceId':
            response = await this.axios.get(
              `admin/address/ilceler/${parentId}/semtler`
            );
            parent.children = response.data.map(semt => {
              return {
                id: 'semtId-' + semt.id,
                name: semt.semt_adi,
                children: []
              };
            });
            break;
          case 'semtId':
            response = await this.axios.get(
              `admin/address/semtler/${parentId}/mahalleler`
            );
            parent.children = response.data.map(mahalle => {
              return {
                id: 'mahalleId-' + mahalle.id,
                name: mahalle.mahalle_adi
              };
            });
            break;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    requestQuery() {
      const filter = {};

      this.filter.map(item => {
        const splitted = item.split('-');
        const key = splitted[0];
        const value = splitted[1];

        if (!(key in filter)) {
          filter[key] = [];
        }

        filter[key].push(value);
      });

      console.log(filter);

      return {
        ...this.options,
        search: this.search,
        filter
      };
    }
  },
  watch: {
    requestQuery: {
      handler: 'getSchoolList',
      deep: true
    }
  }
};
</script>
