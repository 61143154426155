<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Kurum Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" name="name">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Kurum Adı"
                  v-model="form.name"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="ilId">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="İl"
                  item-value="id"
                  item-text="il_adi"
                  v-model="form.ilId"
                  @blur="$v.form.ilId.$touch()"
                  :items="iller"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="ilceId">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="İlçe"
                  item-value="id"
                  item-text="ilce_adi"
                  :disabled="!form.ilId"
                  v-model="form.ilceId"
                  @blur="$v.form.ilceId.$touch()"
                  :items="ilceler"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="semtId">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Semt"
                  item-value="id"
                  item-text="semt_adi"
                  :disabled="!form.ilceId"
                  v-model="form.semtId"
                  @blur="$v.form.semtId.$touch()"
                  :items="semtler"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="mahalleId">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Mahalle"
                  item-value="id"
                  item-text="mahalle_adi"
                  :disabled="!form.semtId"
                  v-model="form.mahalleId"
                  @blur="$v.form.mahalleId.$touch()"
                  :items="mahalleler"
                ></v-select>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['visible'],

  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      ilId: { required },
      ilceId: { required },
      semtId: { required },
      mahalleId: { required }
    }
  },

  data() {
    return {
      loading: true,
      form: {},
      iller: [],
      ilceler: [],
      semtler: [],
      mahalleler: []
    };
  },

  mounted() {
    this.getIlList();
  },

  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          await this.axios.post('admin/schools', {
            ...this.form
          });
          this.close();
          this.$emit('done');
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde eklendi!',
            color: 'success'
          });
        }
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async getIlList() {
      try {
        const { data } = await this.axios.get('admin/address/iller');

        this.iller = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getIlceList() {
      try {
        const { data } = await this.axios.get(
          'admin/address/iller/' + this.form.ilId + '/ilceler'
        );

        this.ilceler = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getSemtList() {
      try {
        const { data } = await this.axios.get(
          'admin/address/ilceler/' + this.form.ilceId + '/semtler'
        );

        this.semtler = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getMahalleList() {
      try {
        const { data } = await this.axios.get(
          'admin/address/semtler/' + this.form.semtId + '/mahalleler'
        );

        this.mahalleler = data;
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {};
    }
  },
  watch: {
    'form.ilId': {
      handler() {
        this.getIlceList();
        this.ilceId = null;
        this.semtId = null;
        this.mahalleId = null;
      }
    },
    'form.ilceId': {
      handler() {
        this.getSemtList();
        this.semtId = null;
        this.mahalleId = null;
      }
    },
    'form.semtId': {
      handler() {
        this.getMahalleList();
        this.mahalleId = null;
      }
    }
  }
};
</script>
